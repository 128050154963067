import React from "react";
import {createMemoryHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";

import "assets/scss/material-kit-pro-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// pages for this product
import UrbanCoPage from "./UrbanCoPage/UrbanCoPage";
let hist = createMemoryHistory();

export default function Index() {
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/" component={UrbanCoPage} />
      </Switch>
    </Router>
  )
}